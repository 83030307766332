import { createApp } from "vue";
import App from "./App.vue";
import "normalize.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import ElementPlus from "element-plus";
import zhCh from "element-plus/es/locale/lang/zh-cn";
import "@/assets/styles/index.scss";
import router from "./router";
import directives from "@/utils/directives.js";
import Table from "@/components/Table/index.vue";
// 图片预览
import openPreview from "@/components/Preview/index.js";
// 权限
import "@/permission";
// 字体
import "./assets/font/font.css";
//svg
import "virtual:svg-icons-register";
// pinia
import { createPinia } from "pinia";
const state = createPinia();
// 数字动画
import CountTo from "vue3-count-to";

const app = createApp(App);
app.config.globalProperties.$openPreview = openPreview;

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.component("Table", Table);

app
  .use(ElementPlus, { locale: zhCh })
  .use(state)
  .use(router)
  .use(CountTo)
  .use(directives)
  .mount("#app");
