<template>
  <el-table
    :data="tableData"
    class="w100"
    header-row-class-name="tableHeader"
    header-cell-class-name="header-cell"
    :row-class-name="rowClassName"
    v-loading="tableLoading"
    @row-click="handleRow"
    height="100%"
    @selection-change="selectChange"
    :highlight-current-row="isHighlight"
    :stripe="stripe"
    :row-key="rowKey"
    :default-expand-all="expandAll"
    :expand-row-keys="rowKeysArr"
    :lazy="lazy"
    :load="load"
    :span-method="mergeMethod"
    :border="border">
    <!-- 多选 -->
    <el-table-column
      type="selection"
      width="55"
      v-if="selection"
      align="center"></el-table-column>
    <!-- 序号 -->
    <el-table-column
      type="index"
      width="55"
      v-if="index"
      label="序号"
      align="center"></el-table-column>
    <!-- 表格业务内容列 -->
    <template v-for="(item, index) in tableHeader">
      <!-- 嵌套表格 -->
      <template v-if="item.type == 'include'">
        <el-table-column
          :key="index"
          :label="item.label"
          align="center">
          <template v-for="(itm, idx) in item.options">
            <!-- 需转换的列 -->
            <el-table-column
              v-if="itm.type == 'function'"
              :prop="itm.prop"
              :label="itm.label"
              align="center">
              <template #default="scope">
                {{ itm.callback && itm.callback(scope.row, itm.prop) }}
              </template>
            </el-table-column>
            <el-table-column
              v-else-if="itm.type == 'image'"
              :prop="itm.prop"
              :label="itm.label"
              align="center">
              <template #default="scope">
                <img
                  class="cursor-pointer"
                  :src="scope.row[itm.prop][0]"
                  @click="preview(scope.row[itm.prop])"
                  style="width: 50px; height: 50px"
                  v-if="scope.row[itm.prop][0]" />
                <span v-else></span>
              </template>
            </el-table-column>
            <!-- 普通列 -->
            <el-table-column
              v-else
              :prop="itm.prop"
              :label="itm.label"
              show-overflow-tooltip
              align="center"></el-table-column>
          </template>
        </el-table-column>
      </template>
      <!-- 嵌套多层表格 -->
      <template v-if="item.type == 'include-multilayer'">
        <el-table-column
          :width="item.width"
          :key="index"
          :label="item.label"
          align="center">
          <template
            v-for="(itm, idx) in item.options"
            :key="idx">
            <el-table-column
              :width="item.width"
              v-if="itm.type == 'second'"
              :label="itm.label"
              show-overflow-tooltip
              align="center">
              <template
                v-for="(it, ix) in itm.options"
                :key="ix">
                <!-- 2层嵌套列 -->
                <el-table-column
                  :width="it.width"
                  v-if="it.type == 'three'"
                  :label="it.label"
                  show-overflow-tooltip
                  align="center">
                  <template
                    v-for="(t, i) in it.options"
                    :key="i">
                    <!-- 3层嵌套列 -->
                    <el-table-column
                      :width="t.width"
                      v-if="t.type == 'four'"
                      :prop="t.prop"
                      :label="t.label"
                      show-overflow-tooltip
                      align="center">
                      <template
                        v-for="(val, sub) in t.options"
                        :key="sub">
                        <!-- 4层转换列 -->
                        <el-table-column
                          :width="val.width"
                          v-if="val.type == 'function'"
                          :prop="val.prop"
                          :label="val.label"
                          align="center">
                          <template #default="scope">
                            {{
                              val.callback && val.callback(scope.row, val.prop)
                            }}
                          </template>
                        </el-table-column>
                        <!-- 4层普通列 -->
                        <el-table-column
                          :width="val.width"
                          v-else
                          :prop="val.prop"
                          :label="val.label"
                          show-overflow-tooltip
                          align="center"></el-table-column>
                      </template>
                    </el-table-column>
                    <!-- 3层转换列 -->
                    <el-table-column
                      :width="t.width"
                      v-else-if="t.type == 'function'"
                      :prop="t.prop"
                      :label="t.label"
                      align="center">
                      <template #default="scope">
                        {{ t.callback && t.callback(scope.row, t.prop) }}
                      </template>
                    </el-table-column>
                    <!-- 3层普通列 -->
                    <el-table-column
                      :width="t.width"
                      v-else
                      :prop="t.prop"
                      :label="t.label"
                      show-overflow-tooltip
                      align="center"></el-table-column>
                  </template>
                </el-table-column>
                <!-- 2层转换列 -->
                <el-table-column
                  :width="it.width"
                  v-else-if="it.type == 'function'"
                  :prop="it.prop"
                  :label="it.label"
                  align="center">
                  <template #default="scope">
                    {{ it.callback && it.callback(scope.row, it.prop) }}
                  </template>
                </el-table-column>
                <!-- 2层普通列 -->
                <el-table-column
                  :width="it.width"
                  v-else
                  :prop="it.prop"
                  :label="it.label"
                  show-overflow-tooltip
                  align="center"></el-table-column>
              </template>
            </el-table-column>
            <!-- 1层转换列 -->
            <el-table-column
              :width="itm.width"
              v-else-if="itm.type == 'function'"
              :prop="itm.prop"
              :label="itm.label"
              align="center">
              <template #default="scope">
                {{ itm.callback && itm.callback(scope.row, itm.prop) }}
              </template>
            </el-table-column>
            <el-table-column
              v-else-if="itm.type == 'input'"
              :prop="itm.prop"
              :label="itm.label"
              align="center">
              <template #default="scope">
                <el-input
                  v-model="scope.row[itm.prop]"
                  v-if="
                    !scope.row.modAddType || scope.row.modAddType !== 'detail'
                  "></el-input>
                <span v-else>{{ scope.row[itm.prop] }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-else-if="itm.type == 'daterange'"
              :prop="itm.prop"
              :label="itm.label"
              align="center"
              :width="!!itm.width ? itm.width : 250">
              <template #default="scope">
                <el-date-picker
                  v-if="
                    !scope.row.modAddType || scope.row.modAddType !== 'detail'
                  "
                  type="daterange"
                  v-model="scope.row[itm.prop]"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="YYYY/MM/DD"
                  format="YYYY/MM/DD"
                  style="width: 100%"></el-date-picker>
                <!-- <el-input-number
                  v-model="scope.row[itm.prop]"
                  v-if="!scope.row.modAddType || scope.row.modAddType !== 'detail'"
                ></el-input-number> -->
                <span v-else>{{ scope.row[itm.prop] }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-else-if="itm.type == 'inputNumber'"
              :prop="itm.prop"
              :label="itm.label"
              align="center">
              <template #default="scope">
                <el-input-number
                  v-model="scope.row[itm.prop]"
                  v-if="
                    !scope.row.modAddType || scope.row.modAddType !== 'detail'
                  "
                  :min="0"></el-input-number>
                <span v-else>{{ scope.row[itm.prop] }}</span>
              </template>
            </el-table-column>
            <!-- 1层普通列 -->
            <el-table-column
              :width="itm.width"
              v-else
              :prop="itm.prop"
              :label="itm.label"
              show-overflow-tooltip
              align="center"></el-table-column>
          </template>
        </el-table-column>
      </template>
      <!-- 非嵌套表格 -->
      <template v-else>
        <!-- 需转换的列 -->
        <el-table-column
          v-if="item.type == 'function'"
          show-overflow-tooltip
          :prop="item.prop"
          :label="item.label"
          align="center">
          <template #default="scope">
            {{ item.callback && item.callback(scope.row, item.prop) }}
          </template>
        </el-table-column>
        <el-table-column
          v-else-if="item.type == 'html'"
          :prop="item.prop"
          :label="item.label"
          align="center"
          :width="item.width">
          <template #default="scope">
            <span
              v-html="
                item.callback && item.callback(scope.row, item.prop)
              "></span>
          </template>
        </el-table-column>
        <el-table-column
          v-else-if="item.type == 'code'"
          :prop="item.prop"
          :label="item.label"
          align="center">
          <template #default="scope">
            <img
              :src="scope.row[item.prop]"
              class="cursor-pointer"
              @click.stop="downloadCode(scope.row)"
              style="width: 50px; height: 50px"
              v-if="scope.row[item.prop]"
              alt="QR Code" />
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column
          v-else-if="item.type == 'image'"
          :prop="item.prop"
          :label="item.label"
          align="center">
          <template #default="scope">
            <img
              :src="scope.row[item.prop][0]"
              class="cursor-pointer"
              @click.stop="preview(scope.row[item.prop])"
              style="width: 50px; height: 50px"
              v-if="scope.row[item.prop][0]" />
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column
          v-else-if="item.type == 'only-image'"
          :prop="item.prop"
          :label="item.label"
          align="center">
          <template #default="scope">
            <img
              :src="scope.row[item.prop]"
              class="cursor-pointer"
              @click.stop="preview([scope.row[item.prop]])"
              style="width: 50px; height: 50px"
              v-if="scope.row[item.prop]" />
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column
          v-else-if="item.type == 'conceal-image'"
          :prop="item.prop"
          :label="item.label"
          align="center">
          <template #default="scope">
            <img
              :src="scope.row[item.prop]"
              class="cursor-pointer"
              @click.stop="preview([scope.row[item.prop]])"
              style="width: 50px; height: 50px"
              v-if="
                scope.row[item.prop] &&
                item.callback &&
                item.callback(scope.row)
              " />
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column
          v-else-if="item.type == 'switch'"
          :prop="item.prop"
          :label="item.label"
          align="center">
          <template #default="scope">
            <el-switch
              :model-value="scope.row[item.prop]"
              v-if="scope.row[item.prop] !== ''"
              @change="handleTableSwitch($event, scope.row)"
              :active-value="item.actValue !== undefined ? item.actValue : true"
              :inactive-value="
                item.inactValue !== undefined ? item.inactValue : false
              " />
          </template>
        </el-table-column>
        <!-- select选择器 -->
        <el-table-column
          v-else-if="item.type == 'select'"
          :prop="item.prop"
          :label="item.label"
          align="center">
          <template #default="scope">
            <el-select
              v-model="scope.row[item.prop]"
              placeholder="请选择"
              clearable
              v-if="!scope.row.modAddType || scope.row.modAddType !== 'detail'">
              <el-option
                v-for="itm in item.options"
                :key="itm"
                :label="itm.label"
                :value="itm.label">
              </el-option>
            </el-select>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
        <!-- 年份选择 -->
        <el-table-column
          v-else-if="item.type == 'year'"
          :prop="item.prop"
          :label="item.label"
          align="center"
          :width="!!item.width ? item.width : 180">
          <template #default="scope">
            <el-date-picker
              v-model="scope.row[item.prop]"
              type="year"
              placeholder="选择年份"
              value-format="YYYY"
              format="YYYY"
              clearable
              v-if="!scope.row.modAddType || scope.row.modAddType !== 'detail'"
              style="width: 100%">
            </el-date-picker>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
        <!-- 日期选择 -->
        <el-table-column
          v-else-if="item.type == 'date'"
          :prop="item.prop"
          :label="item.label"
          align="center"
          :width="!!item.width ? item.width : 180">
          <template #default="scope">
            <el-date-picker
              v-model="scope.row[item.prop]"
              type="year"
              placeholder="选择日期"
              value-format="YYYY-MM-DD"
              format="YYYY-MM-DD"
              clearable
              v-if="!scope.row.modAddType || scope.row.modAddType !== 'detail'"
              style="width: 100%">
            </el-date-picker>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
        <!-- 年份选择 -->
        <el-table-column
          v-else-if="item.type == 'year'"
          :prop="item.prop"
          :label="item.label"
          align="center"
          :width="!!item.width ? item.width : 180">
          <template #default="scope">
            <el-date-picker
              v-model="scope.row[item.prop]"
              type="year"
              placeholder="选择年份"
              value-format="YYYY"
              format="YYYY"
              clearable
              v-if="!scope.row.modAddType || scope.row.modAddType !== 'detail'"
              style="width: 100%">
            </el-date-picker>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
        <!-- 日期选择 -->
        <el-table-column
          v-else-if="item.type == 'date'"
          :prop="item.prop"
          :label="item.label"
          align="center"
          :width="!!item.width ? item.width : 180">
          <template #default="scope">
            <el-date-picker
              v-model="scope.row[item.prop]"
              type="year"
              placeholder="选择日期"
              value-format="YYYY-MM-DD"
              format="YYYY-MM-DD"
              clearable
              v-if="!scope.row.modAddType || scope.row.modAddType !== 'detail'"
              style="width: 100%">
            </el-date-picker>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
        <!-- 年份选择 -->
        <el-table-column
          v-else-if="item.type == 'year'"
          :prop="item.prop"
          :label="item.label"
          align="center"
          :width="!!item.width ? item.width : 180">
          <template #default="scope">
            <el-date-picker
              v-model="scope.row[item.prop]"
              type="year"
              placeholder="选择年份"
              value-format="YYYY"
              format="YYYY"
              clearable
              v-if="!scope.row.modAddType || scope.row.modAddType !== 'detail'"
              style="width: 100%">
            </el-date-picker>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
        <!-- 日期选择 -->
        <el-table-column
          v-else-if="item.type == 'date'"
          :prop="item.prop"
          :label="item.label"
          align="center"
          :width="!!item.width ? item.width : 180">
          <template #default="scope">
            <el-date-picker
              v-model="scope.row[item.prop]"
              type="year"
              placeholder="选择日期"
              value-format="YYYY-MM-DD"
              format="YYYY-MM-DD"
              clearable
              v-if="!scope.row.modAddType || scope.row.modAddType !== 'detail'"
              style="width: 100%">
            </el-date-picker>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
        <!-- 年份选择 -->
        <el-table-column
          v-else-if="item.type == 'year'"
          :prop="item.prop"
          :label="item.label"
          align="center"
          :width="!!item.width ? item.width : 180">
          <template #default="scope">
            <el-date-picker
              v-model="scope.row[item.prop]"
              type="year"
              placeholder="选择年份"
              value-format="YYYY"
              format="YYYY"
              clearable
              v-if="!scope.row.modAddType || scope.row.modAddType !== 'detail'"
              style="width: 100%">
            </el-date-picker>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
        <!-- 日期选择 -->
        <el-table-column
          v-else-if="item.type == 'dateSelect'"
          :prop="item.prop"
          :label="item.label"
          align="center"
          :width="!!item.width ? item.width : 180">
          <template #default="scope">
            <el-date-picker
              v-model="scope.row[item.prop]"
              type="date"
              placeholder="选择日期"
              value-format="YYYY-MM-DD"
              format="YYYY-MM-DD"
              clearable
              v-if="!scope.row.modAddType || scope.row.modAddType !== 'detail'"
              style="width: 100%">
            </el-date-picker>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
        <!-- 日期选择 -->
        <el-table-column
          v-else-if="item.type == 'date'"
          :prop="item.prop"
          :label="item.label"
          align="center"
          :width="!!item.width ? item.width : 180">
          <template #default="scope">
            <el-date-picker
              v-model="scope.row[item.prop]"
              type="year"
              placeholder="选择日期"
              value-format="YYYY-MM-DD"
              format="YYYY-MM-DD"
              clearable
              v-if="!scope.row.modAddType || scope.row.modAddType !== 'detail'"
              style="width: 100%">
            </el-date-picker>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
        <!-- 年份选择 -->
        <el-table-column
          v-else-if="item.type == 'year'"
          :prop="item.prop"
          :label="item.label"
          align="center"
          :width="!!item.width ? item.width : 180">
          <template #default="scope">
            <el-date-picker
              v-model="scope.row[item.prop]"
              type="year"
              placeholder="选择年份"
              value-format="YYYY"
              format="YYYY"
              clearable
              v-if="!scope.row.modAddType || scope.row.modAddType !== 'detail'"
              style="width: 100%">
            </el-date-picker>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
        <!-- 日期选择 -->
        <el-table-column
          v-else-if="item.type == 'date'"
          :prop="item.prop"
          :label="item.label"
          align="center"
          :width="!!item.width ? item.width : 180">
          <template #default="scope">
            <el-date-picker
              v-model="scope.row[item.prop]"
              type="year"
              placeholder="选择日期"
              value-format="YYYY-MM-DD"
              format="YYYY-MM-DD"
              clearable
              v-if="!scope.row.modAddType || scope.row.modAddType !== 'detail'"
              style="width: 100%">
            </el-date-picker>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
        <!-- 年份选择 -->
        <el-table-column
          v-else-if="item.type == 'year'"
          :prop="item.prop"
          :label="item.label"
          align="center"
          :width="!!item.width ? item.width : 180">
          <template #default="scope">
            <el-date-picker
              v-model="scope.row[item.prop]"
              type="year"
              placeholder="选择年份"
              value-format="YYYY"
              format="YYYY"
              clearable
              v-if="!scope.row.modAddType || scope.row.modAddType !== 'detail'"
              style="width: 100%">
            </el-date-picker>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
        <!-- 日期选择 -->
        <el-table-column
          v-else-if="item.type == 'date'"
          :prop="item.prop"
          :label="item.label"
          align="center"
          :width="!!item.width ? item.width : 180">
          <template #default="scope">
            <el-date-picker
              v-model="scope.row[item.prop]"
              type="year"
              placeholder="选择日期"
              value-format="YYYY-MM-DD"
              format="YYYY-MM-DD"
              clearable
              v-if="!scope.row.modAddType || scope.row.modAddType !== 'detail'"
              style="width: 100%">
            </el-date-picker>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
        <!-- input输入框 modAddType赋予动态可编辑属性-->
        <el-table-column
          v-else-if="item.type == 'input'"
          :prop="item.prop"
          :label="item.label"
          align="center">
          <template #default="scope">
            <el-input
              v-model="scope.row[item.prop]"
              v-if="
                !scope.row.modAddType || scope.row.modAddType !== 'detail'
              "></el-input>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
        <!-- input数字输入框 -->
        <el-table-column
          v-else-if="item.type == 'inputNumber'"
          :prop="item.prop"
          :label="item.label"
          align="center"
          :width="!!item.width ? item.width : 180">
          <template #default="scope">
            <el-input-number
              v-if="!scope.row.modAddType || scope.row.modAddType !== 'detail'"
              v-model="scope.row[item.prop]"
              :min="0"></el-input-number>
          </template>
        </el-table-column>
        <!-- 置顶效果列 -->
        <el-table-column
          v-else-if="item.type == 'top'"
          show-overflow-tooltip
          :prop="item.prop"
          :label="item.label"
          align="center">
          <template #default="scope">
            <span v-if="scope.row.top == 1"
              ><span style="color: red">[顶]</span
              >{{ scope.row[item.prop] }}</span
            >
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
        <!-- 文件下载 -->
        <el-table-column
          v-else-if="item.type == 'download'"
          :prop="item.prop"
          :label="item.label"
          align="center">
          <template #default="scope">
            <!-- <span class="cursor-pointer file-name" @click="download(scope.row[item.prop].filePath.split(',')[idx])" v-for="(itm,idx) in scope.row[item.prop].fileName.split(',')" :key="idx">{{ itm }}</span> -->
            <span
              class="cursor-pointer file-name"
              @click="download(scope.row[item.prop], idx)"
              v-for="(itm, idx) in scope.row[item.prop].fileName.split(',')"
              :key="idx"
              >{{ itm }}</span
            >
          </template>
        </el-table-column>
        <!-- 文件查看 -->
        <el-table-column
          v-else-if="item.type == 'fileShow'"
          :prop="item.prop"
          :label="item.label"
          align="center">
          <template #default="scope">
            <!-- <span class="cursor-pointer file-name" @click="download(scope.row[item.prop].filePath.split(',')[idx])" v-for="(itm,idx) in scope.row[item.prop].fileName.split(',')" :key="idx">{{ itm }}</span> -->
            <!-- <div class="cursor-pointer file-name" v-for="(itm, idx) in scope.row[item.prop].fileName.split(',')"
              :key="idx">{{ itm }}</div> -->
            <div
              @click="fileShow(scope.row)"
              style="
                font-family: Source Han Sans CN, Source Han Sans CN;
                font-weight: 400;
                font-size: 14px;
                color: #6cb444;
                margin-left: 10px;
                cursor: pointer;
              ">
              {{ scope.row.fileNames }}
            </div>
          </template>
        </el-table-column>

        <!-- 日期选择器 -->
        <el-table-column
          width="240"
          v-else-if="item.type == 'date'"
          :prop="item.prop"
          :label="item.label"
          align="center">
          <template #default="scope">
            <el-date-picker
              v-model="scope.row[item.prop]"
              type="date"
              placeholder="选择日期"
              value-format="YYYY-MM-DD"></el-date-picker>
          </template>
        </el-table-column>

        <!-- 文件上传 -->
        <el-table-column
          v-else-if="item.type == 'fileUpload'"
          :prop="item.prop"
          :label="item.label"
          align="center">
          <template #default="scope">
            <FileUpload3
              v-if="scope.row.modAddType !== 'detail'"
              v-model="scope.row[item.prop]"
              :limit="1"></FileUpload3>
            <div v-else>
              <span
                style="
                  font-family: Source Han Sans CN, Source Han Sans CN;
                  font-weight: 400;
                  font-size: 14px;
                  color: #0fa2f2;
                  margin-left: 10px;
                  cursor: pointer;
                "
                link
                @click="doPreview(scope.row[item.prop][0])"
                >{{
                  scope.row[item.prop][0].name
                    ? scope.row[item.prop][0].name
                    : scope.row[item.prop][0].fileName
                }}</span
              >
            </div>
          </template>
        </el-table-column>
        <!-- 附件下载-->
        <el-table-column
          v-else-if="item.type == 'downloadAccessory'"
          :prop="item.prop"
          :label="item.label"
          show-overflow-tooltip
          align="center">
          <template #default="scope">
            <el-button
              type="primary"
              size="default"
              v-if="scope.row[item.prop] != ''"
              @click="downloadAccessory(scope.row[item.prop])"
              >下载</el-button
            >
          </template>
        </el-table-column>
        <!-- tag标签列 在职/离职-->
        <el-table-column
          v-else-if="item.type == 'tag'"
          :prop="item.prop"
          :label="item.label"
          show-overflow-tooltip
          align="center">
          <template #default="scope">
            <el-tag
              class="ml-2"
              v-if="scope.row[item.prop] != ''"
              :type="scope.row[item.prop] == '在职' ? 'success' : 'danger'"
              >{{ scope.row[item.prop] }}</el-tag
            >
          </template>
        </el-table-column>
        <!-- tag标签列 已发布/未发布/已撤回-->
        <el-table-column
          v-else-if="item.type == 'tagIssued'"
          :prop="item.prop"
          :label="item.label"
          show-overflow-tooltip
          align="center">
          <template #default="scope">
            <el-tag
              class="ml-2"
              :type="
                scope.row[item.prop] == '已发布'
                  ? 'success'
                  : scope.row[item.prop] == '未发布'
                  ? 'info'
                  : 'warning'
              "
              >{{ scope.row[item.prop] }}</el-tag
            >
          </template>
        </el-table-column>
        <!-- 上架下架-->
        <el-table-column
          v-else-if="item.type == 'upShelf'"
          :prop="item.prop"
          :label="item.label"
          show-overflow-tooltip
          align="center">
          <template #default="scope">
            <p
              :style="{
                color:
                  scope.row[item.prop] == '未上架'
                    ? '#909399'
                    : scope.row[item.prop] == '已上架'
                    ? '#67C23A'
                    : '#FFA200',
              }"
              v-if="scope.row[item.prop] != ''">
              {{ scope.row[item.prop] }}
            </p>
          </template>
        </el-table-column>
        <!-- 富文本转换列 -->
        <el-table-column
          v-else-if="item.type == 'eidtor'"
          :prop="item.prop"
          :label="item.label"
          show-overflow-tooltip
          align="center">
          <template #default="scope">
            <p v-html="scope.row[item.prop]"></p>
          </template>
        </el-table-column>
        <!-- 普通列 -->
        <el-table-column
          v-else
          :prop="item.prop"
          :label="item.label"
          :show-overflow-tooltip="tooltip"
          align="center"
          :width="item.width"></el-table-column>
      </template>
    </template>
    <!-- 表格操作列 -->
    <el-table-column
      label="操作"
      v-if="isOperate"
      :width="width + 'px'"
      align="center"
      fixed="right">
      <template #default="scope">
        <slot
          name="operate"
          :scope="scope"></slot>
      </template>
    </el-table-column>
  </el-table>
</template>

<script setup>
  import { defineOptions, getCurrentInstance } from "vue";
  import { loadGet } from "@/api/common/load";
  import { linkDownload } from "@/utils/downLoadFile";

  defineOptions({
    name: "Table",
  });
  const { appContext } = getCurrentInstance();
  const global = appContext.config.globalProperties;
  const emit = defineEmits([
    "rowClick",
    "selectChange",
    "handleTableSwitch",
    "downloadAccessory",
    "downloadCode",
    "selectableMethod",
  ]);
  const props = defineProps({
    // 表格行点击
    isRowClick: {
      type: Boolean,
      default: () => false,
    },
    // 尺寸
    size: {
      type: String,
      default: () => "small",
    },
    // 表格显示的数据
    tableData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    // 表头
    tableHeader: {
      type: Array,
      default: function () {
        return [];
      },
    },
    // 控制操作列是否显示
    isOperate: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    // 多选列是否显示
    selection: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    // 序号列是否显示
    index: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    // 操作列宽度
    width: {
      type: [Number, String],
      default: function () {
        return 150;
      },
    },
    // 表格加载动画
    tableLoading: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    // 单选
    isHighlight: {
      type: Boolean,
      default: () => false,
    },
    //联级效果
    rowKey: {
      type: String,
      default: function () {
        return "id";
      },
    },
    //是否为斑马纹
    stripe: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    //树形表格是否全部展开
    expandAll: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    //是否懒加载
    lazy: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    //默认展开行
    rowKeysArr: {
      type: Array,
      default: function () {
        return [];
      },
    },
    //懒加载时传入url
    url: {
      type: String,
      default: function () {
        return "";
      },
    },
    rowClassName: {
      type: String,
      default: function () {
        return "row-class";
      },
    },
    //是否合并
    isMerge: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    //合并列数
    mergeCloum: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    //是否边框
    border: {
      type: Boolean,
      default: function () {
        return false;
      },
    },

    tooltip: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
  });

  //  预览查看
  const fileShow = (file) => {
    console.log(file);
    if (file.fileList && file.fileList.length > 0) {
      window.open(file.fileList[0].path, "_blank");
    } else if (file && file.url) {
      window.open(file.url, "_blank");
    }

    // downLoadFile(file)
  };
  const handleRow = (row) => {
    props.isRowClick ? emit("rowClick", row) : "";
  };
  const selectChange = (val) => {
    emit("selectChange", val);
  };
  const preview = (urlList) => {
    global.$openPreview(urlList);
  };
  const handleTableSwitch = (e, row) => {
    emit("handleTableSwitch", { switchValue: e, ...row });
  };
  const load = (tree, treeNode, resolve) => {
    let url = props.url;
    loadGet(url, { pid: tree.id })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const downloadAccessory = (row) => {
    emit("downloadAccessory", row);
  };

  //下载
  const doPreview = (file) => {
    let url = file.path || file.url;
    let fileName = file.fileName || file.name;
    linkDownload(url, fileName);
  };

  const mergeMethod = (val) => {
    if (props.isMerge) {
      let { row, column, rowIndex, columnIndex } = val;
      if (rowIndex == props.tableData.length - 1) {
        if (columnIndex === 0) {
          return {
            rowspan: 1,
            colspan: props.mergeCloum,
          };
        } else if (columnIndex < props.mergeCloum && columnIndex > 0) {
          return [0, 0];
        }
      }
    }
  };
  const downloadCode = (row) => {
    emit("downloadCode", { ...row });
  };
</script>

<style lang="scss" scoped>
  :deep(.tableHeader th) {
    background: #f6f6f6 !important;
    color: #333333 !important;
    height: 60px;
    font-size: 14px;
    font-weight: bold;
  }

  // :deep(.el-table__cell) {
  //     border: none !important;
  // }

  :deep(.row-class) {
    height: 80px !important;
  }

  :deep(.price-row-class) {
    height: 48px !important;
  }
</style>
