<template>
  <el-dialog
    v-model="show"
    title="温馨提示"
    center
    :close-on-click-modal="false">
    <main
      style="
        height: 300px;
        font-size: 24px;
        line-height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      ">
      <div style="width: 800px; text-indent: 48px">
        当前账号暂未开通商户号，为避免上架商品后影响正常交易，建议您先开通商户号，再进行商品上架交易！
      </div>
    </main>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="show = false">暂不开户</el-button>
        <el-button
          type="primary"
          @click="jump()">
          去开户
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
  import router from "@/router";

  const show = ref(false);
  const jump = () => {
    show.value = false;
    router.push("/form-fill/merchant-account-application");
  };
  defineExpose({
    show,
  });
</script>

<style lang="scss" scoped>
  .box {
  }
</style>
