import dayjs from "dayjs";

export const require = (imgPath) => {
    try {
        const handlePath = imgPath.replace("@", "..");
        // https://vitejs.cn/guide/assets.html#the-public-directory
        return new URL(handlePath, import.meta.url).href;
    } catch (error) {
        console.warn(error);
    }
};

export const resolvePath = (pathArr, currentRoute) => {
    return pathArr.some((item) => {
        return currentRoute.includes(item);
    });
};

// 时间戳格式转换 format HH:mm
export const parseTime = (time, format = "YYYY-MM-DD HH:mm:ss") => {
    return dayjs(new Date(time)).format(format);
};

// 获取assets静态资源
export const getAssetsFile = (url) => {
    return new URL(`../assets/images/${url}`, import.meta.url).href;
};

// 在线磋商获取最后的消息
export function getLastMsg(data) {
    if (data.text) return data.text

    data.payload =
        data.payload instanceof Object ? data.payload : JSON.parse(data.payload);
    data.customData =
        data.customData instanceof Object
            ? data.customData
            : JSON.parse(data.customData);
    let text = "";
    switch (data.type) {
        case 0:
            if (data.customData.isLink) {
                text = "[链接]";
            } else {
                text = data.payload.text;
            }
            break;
        case 1:
            text = "[图片]";
            break;
    }
    return text;
}
// echarts根据不同的屏幕宽度换算字体大小
export function transformFontSize(res) {
    let clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
    if (!clientWidth) return;
    let fontSize = clientWidth / 1920;
    return res * fontSize;
}

/**
 * @description: 隐藏手机号中间四位数为****
 * @param {*} tel 手机号
 * @return {*}
 */
export function formatPhone(tel) {
    var reg = /^(\d{3})\d{4}(\d{4})$/;
    return tel.replace(reg, "$1****$2");
}

/**
 * @param {Object} 是否是手机号
 */
export function isPhoneNumber(input) {
    // 使用正则表达式进行校验
    const regExp = /^[1][3-9]\d{9}$/;
    return regExp.test(input);
}
