import router from '@/router'
import useStore from '@/store'
import { ElMessage } from 'element-plus'
import { scrollTo } from '@/utils/scroll-to'
import { resolvePath } from '@/utils/common.js'
import Cookies from "@/plugin/cookies/cookie.js";
import {Messages} from "@/utils/message.js";

router.beforeEach(async (to, form, next) => {
    // scrollTo(0, 0)
    const { user } = useStore()
    const token = user.token
    const path = to.path
    const validPath = ['/user-center', '/order-payment', '/form-fill', '/customer-service', '/large-screen']
    if (token) {
        await user.getInfo()
        if (to.path.includes('/large-screen')) {
            if (user.roles.includes('SUPERVISE')) {
                next()
                return
            }
            ElMessage.warning('无权访问！')
            next('/')
        } else {
            next()
        }
    } else {
        if (resolvePath(validPath, path)) {
            ElMessage.warning('请登录！')
            next('/')
        } else {
            next()
        }
    }
})

router.afterEach((to, from) => {
    if (to.path != from.path) {
        scrollTo(0, 0)
    }
})
