import axios from "axios"

var func = {
    fileDownLoad(response, fileName) {
        const url = window.URL.createObjectURL(response.data) // 创建一个新的 URL 对象
        // 以下代码一句话解释，在页面上生成一个a标签并指定href为上面的url,然后模拟点击，以实现自动下载
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = fileName
        a.click()
        window.URL.revokeObjectURL(url)
        a.remove()
    }
}
export function downLoadFile(response, qdFileName = '') {
    const headers = response.headers

    // 后端自定义文件名
    if (qdFileName == '' || qdFileName == null || qdFileName == undefined) {
        var fileName = ''
        const disposition = headers['content-disposition']
        if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            var matches = filenameRegex.exec(disposition)
            if (matches != null && matches[1]) {
                fileName = decodeURI(matches[1].replace(/['"]/g, ''))
            }
        }
        func.fileDownLoad(response, fileName)
    } else {
        // 前端自定义文件名
        func.fileDownLoad(response, qdFileName)
    }
};


export const linkDownload = (url, fileName) => {
    const link = document.createElement('a');
    link.href = url; // 替换为Excel文件的下载链接
    link.target = '_blank';
    link.download = fileName; // 替换为Excel文件的名称
    // 触发点击事件
    link.click();
    link.remove()
}

export const exportFile = (res, fileName) => {
    const link = document.createElement('a');
    link.href = res.request.responseURL; // 替换为Excel文件的下载链接
    // 触发点击事件
    link.click();
}

export const exportFileXlsx = (res, fileName) => {
    let blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8'
    })
    let a = document.createElement('a')
    let url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.style.display = 'none'
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
}

export const urlDownload = async (url, fileName) => {
    let response = await fetch(url)
    let blob = await response.blob()
    let objectUrl = window.URL.createObjectURL(blob)
    let a = document.createElement('a')
    a.href = objectUrl
    a.download = fileName
    a.click()
    a.remove()
}
