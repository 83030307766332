<template>
    <el-image-viewer v-if="isShow" :urlList="previewImages" :initialIndex="initialIndex" @close="closeViewer"></el-image-viewer>
</template>

<!-- <script setup>
import { reactive, toRefs, ref, computed, onMounted, defineComponent } from 'vue'
import { ElImageViewer } from 'element-plus'
const isShow = ref(false)
const previewImages = ref([])
const closeViewer = () => {
    isShow.value = false
}
</script> -->
<script>
import { defineComponent, ref } from 'vue'
import { ElImageViewer } from 'element-plus'
export default defineComponent({
    components: { ElImageViewer },
    setup() {
        let isShow = ref(false)
        let previewImages = ref([])
        let initialIndex = ref(0)
        let closeViewer = () => {
            isShow.value = false
        }
        return {
            isShow,
            previewImages,
            initialIndex,
            closeViewer
        }
    }
})
</script>

<style lang='scss' scoped>
</style>
  