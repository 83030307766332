import { createApp } from 'vue'
import PreviewItem from './preview.vue'

const Preview = createApp(PreviewItem)
const dom = document.createElement('div')
const vm = Preview.mount(dom)
document.body.appendChild(dom)
const openPreview = (imgs = [], initialIndex = 0) => {
    vm.isShow = true
    vm.previewImages = imgs
    vm.initialIndex = initialIndex
}

export default openPreview