import { createApp } from "vue";
import OpenMerchantNumTipVueItem from "./OpenMerchantNumTip.vue";

const MerchantNumTipVue = createApp(OpenMerchantNumTipVueItem);
const dom = document.createElement("div");
const vm = MerchantNumTipVue.mount(dom);
document.body.appendChild(dom);

const OpenMerchantNumTip = () => {
  vm.show = true;
};

export default OpenMerchantNumTip;
